<template>
  <div class="wrapLang">
    <img class="logo" alt="Vue logo" src="../../public/img/logos/logo-new-512.png">
    <div class="btnContainer">
      <LangButton @click="setLanguage('cy')" :url="'https://apmabwysiadu.cymru//'">Cymraeg
      </LangButton>
      <!--      <LangButton :url="'https://apmabwysiadu.cymru//'">Cymraeg-->
      <!--      </LangButton>-->
      <LangButton @click="setLanguage('en')" :url="'https://adoptionapp.wales/'">English
      </LangButton>
      <!--      <LangButton :url="'https://adoptionapp.wales/'">English-->
      <!--      </LangButton>-->
    </div>
  </div>
</template>

<script>

import LangButton from '../components/partials/LangButton.vue';

export default {
  name: 'Language',
  components: {
    LangButton,
  },
  beforeCreate() {
    const baseUrl = window.location.origin;
    if (baseUrl === 'https://apmabwysiadu.cymru/') {
      localStorage.setItem('lang', 'cy');
    } else {
      localStorage.setItem('lang', 'en');
    }
  },
  methods: {
    setLanguage(lang) {
      localStorage.setItem('lang', lang);
    },
  },
};
</script>

<style scoped lang="scss">
.wrapLang {
  background: #fff;
  min-height: 100vh;
  min-width: 100%;
  margin: 0;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .logo {
    margin: 0 auto;
    width: 15rem;

    @media (min-width: $lg) {
      width: 20rem;
    }
  }

  .btnContainer {
    padding: 1rem;
    text-align: center;
  }
}
</style>
