<template>
  <div class="home main">
    <TitleText v-if="language === 'cy'">Croeso i ap NAS</TitleText>
    <TitleText v-if="language === 'en'">Welcome to the NAS app</TitleText>
    <div class="container">
      <div class="filter-wrap">
        <DropdownFilter @select="filter_guides"></DropdownFilter>
      </div>
      <div class="flex-grid">
        <Card
          v-for="guide in guides"
          v-bind:key="guide.ID"
          v-bind:title="guide.post_title"
          v-bind:category="guide.terms"
          v-bind:image="guide.thumbnail"
          v-bind:id="guide.ID"></Card>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleText from '@/components/TitleText.vue';
import DropdownFilter from '@/components/DropdownFilter.vue';
import Card from '@/components/Card.vue';
import Guides from '@/js/Guides';

export default {
  name: 'Home',
  components: {
    TitleText,
    DropdownFilter,
    Card,
  },
  data() {
    return {
      guides: [],
      filter: false,
      baseUrl: window.location.origin,
      language: localStorage.getItem('lang'),
    };
  },
  // beforeCreate() {
  //   if (!localStorage.getItem('lang')) {
  //     if (this.baseUrl === 'https://apmabwysiadu.cymru/') {
  //       localStorage.setItem('lang', 'cy');
  //     } else {
  //       localStorage.setItem('lang', 'en');
  //     }
  //   }
  // },
  mounted() {
    this.guides = Guides.get_guides();
  },
  methods: {
    filter_guides(el) {
      this.filter = el;

      this.guides = Guides.filter_guides(el);
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  margin-bottom: 2rem;

  .filter-wrap {
    margin-bottom: 2.5rem;
  }

  .result-wrap {
    margin-bottom: 1rem;
  }

  .flex-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: end;
  }
}
</style>
