import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import Guides from '@/js/Guides';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import en from './locale/en.json';
import cy from './locale/cy.json';

// eslint-disable-next-line no-restricted-globals
if (location.protocol !== 'https:' && process.env.NODE_ENV !== 'development') {
  // eslint-disable-next-line no-restricted-globals
  location.protocol = 'https:';
}


const baseUrl = window.location.origin;
if (!localStorage.getItem('lang')) {
  if (baseUrl === 'https://apmabwysiadu.cymru/') {
    localStorage.setItem('lang', 'cy');
  } else {
    localStorage.setItem('lang', 'en');
  }
}

Guides.refresh()
  .finally(() => {
    console.log('refreshing guides');
    const i18n = createI18n({
      locale: localStorage.getItem('lang'),
      messages: {
        en,
        cy,
      },
    });

    const app = createApp(App);
    app.use(i18n);
    app.use(router);
    app.mount('#app');

    app.config.globalProperties.$filters = {
      capitaliseFirstLetter(string) {
        return string.charAt(0)
          .toUpperCase() + string.slice(1);
      },
    };
  });

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();

  window.installPrompt = e;
});

window.addEventListener('install', (event) => {
  console.log('install event fired.');
  event.waitUntil(
    caches.open('pdf')
      .then((cache) => {
        cache.addAll(
          Guides.get_guides().map((el) => el.file_url.link),
        );

        console.log('cached on install');
      }),
  );
});
